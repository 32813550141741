import React from 'react'
import { graphql } from 'gatsby'
import { css } from 'linaria'
import Img from 'gatsby-image'

import Layout from 'components/Layout'
import FooterCTA from 'components/Footer/CTA'

import Button from 'components/Button'
import Section from 'components/Section'
import SectionContent from 'components/Section/Content'
import NonStretchedImage from '../../components/NonStretchedImage'
import { HeroHeading, Subheading, Lead } from '../../components/TextElements'

import Hero from '../../elements/Hero'
import TechnologiesList from '../../elements/TechnologiesList'
import SmallTestimonials from '../../elements/Testimonials/Small'
import IconBox from '../../elements/IconBox'
import Products from '../../elements/Products'
import FAQ from '../../elements/FAQ'

import rhythm from '../../utils/rhythm'
import PostTile from '../../components/Blog/PostTile'

const ogImage = '/img/og/services-jamstack-og.jpg'

const heroContent = (
  <>
    <Subheading>JAMstack</Subheading>
    <HeroHeading>
      Get more customers with SEO-friendly marketing websites
    </HeroHeading>
    <Lead>
      There’s a thing Google and your customers have in common. They both like
      fast sites. Try JAMstack approach to get ranked higher and gain new
      visitors. Then, let your website turn them into customers.
    </Lead>
    <Button to="/services/jamstack-website/#jamstack">View more</Button>
  </>
)

export default ({ location, data }) => (
  <Layout
    location={location}
    title="Blazing fast JAMstack websites can help you rank better among other things."
    description="JAMstack websites. Amazingly fast and nimble high security pages in service of your business goals."
    image={ogImage}
    preLinksComponent={<FooterCTA />}
  >
    <Hero
      content={heroContent}
      image={data.hero}
      className={css`
        ${{ paddingBottom: rhythm(2) }}
      `}
    />
    <Section
      variant="lightGray"
      id="jamstack"
      footer={
        <Button to={'/blog/jamstack/'} state={{ fromBlog: false }}>
          Read more
        </Button>
      }
      footerClassName={css`
        ${{ marginTop: rhythm(1) }};
      `}
    >
      <SectionContent
        wide
        className={css`
          ${{ marginTop: 0, alignItems: 'center' }};
        `}
      >
        <div>
          <h2>What is JAMstack</h2>
          <Lead>
            By definition, JAMstack is an alternative approach for building
            websites - the JAM here stands for JavaScript, APIs, and Markdown.
            For you, it means faster websites with better SEO ranking
            capabilities and happier users overall.
          </Lead>
          <Lead>
            We build them using battle-tested technologies like GatsbyJS, Hugo,
            Contentful, Netlify, and other headless CMSes.
          </Lead>
        </div>
        <div>
          <NonStretchedImage
            fluid={data.whatIllu.childImageSharp.fluid}
            style={{ marginRight: 0 }}
          />
        </div>
      </SectionContent>
    </Section>

    <Section>
      <SectionContent
        wide
        columnGap="2"
        rowGap="1"
        className={css`
          ${{
            marginTop: 0,
            gridTemplateColumns: 'repeat(auto-fill, minmax(16rem, 1fr))'
          }};
        `}
      >
        <header>
          <Subheading>Sites with Benefits</Subheading>
          <h2>How JAMstack supports your marketing</h2>
        </header>
        <IconBox>
          <Img
            fixed={data.benefitPerformance.childImageSharp.fixed}
            alt="Excellent performance"
          />
          <h3>Excellent performance</h3>
          <p>
            No database means no time-consuming multiple queries. Static sites
            are faster sites, even up to 10x.
          </p>
        </IconBox>
        <IconBox>
          <Img
            fixed={data.benefitSeo.childImageSharp.fixed}
            alt="SEO Advantage"
          />
          <h3>SEO Advantage</h3>
          <p>
            Going static also means better ranking possibilities, what equals
            more traffic and more $$$.
          </p>
        </IconBox>
        <IconBox>
          <Img
            fixed={data.benefitSafety.childImageSharp.fixed}
            alt="SEO Advantage"
          />
          <h3>Safety</h3>
          <p>
            Static websites with no plugins or databases are like a fortress.
            Plus, more secure site means fewer security expenses.
          </p>
        </IconBox>
        <IconBox>
          <Img
            fixed={data.benefitContentManagement.childImageSharp.fixed}
            alt="No-sweat content management"
          />
          <h3>No-sweat content management</h3>
          <p>
            Headless CMSes let you build a website that is both easy to use and
            customize freely.
          </p>
        </IconBox>
        <IconBox>
          <Img
            fixed={data.benefitUptime.childImageSharp.fixed}
            alt="Reliable uptime & lower costs"
          />
          <h3>Reliable uptime & lower costs</h3>
          <p>
            Traffic bigger than usual? Not an issue here. With JAMstack
            marketing websites uptime is anytime. And doesn’t cost a fortune.
          </p>
        </IconBox>
      </SectionContent>
    </Section>
    <Section variant="lightGray">
      <SectionContent columnGap="3">
        <div>
          <Subheading>Featured JAMstack marketing website</Subheading>
          <h2>GatsbyJS and Sanity headless CMS site for Just Technologies</h2>
          <Img
            fluid={data.caseStudy.childImageSharp.fluid}
            alt="Just Case study"
            className={css`
              ${{ marginTop: rhythm(2) }};
            `}
          />
        </div>
        <div
          className={css`
            ${{ marginTop: rhythm(-1 / 3) }};
          `}
        >
          <Lead noMargin>
            Just is a fintech company that creates cloud-native software
            products for corporate clients to help them manage their FX
            operations, same as risk and liquidity.
          </Lead>
          <Lead noMargin>
            What they needed was a sharp and fast informative website for their
            prospects, job candidates, and investors, that is easy to edit and
            works in multiple languages.
          </Lead>
          <Lead noMargin>
            What we did was building a fast and easy-editable website with
            GatsbyJS, implementing Sanity headless CMS that enables custom
            configuration, and deploying the content with Netlify.
          </Lead>
          <Button
            to={'/blog/case-study-just-technologies/'}
            className={css`
              ${{ marginTop: rhythm(2 / 3) }};
            `}
          >
            Read more
          </Button>
        </div>
      </SectionContent>
    </Section>
    <Section
      header={
        <>
          <Subheading>Technologies we use</Subheading>
          <h2>Beautifying the web</h2>
        </>
      }
    >
      <TechnologiesList />
    </Section>
    <Section
      variant="lightGray"
      header={
        <>
          <Subheading>Workflow</Subheading>
          <h2>How we get it done</h2>
        </>
      }
    >
      <SectionContent
        columnGap={`calc(4vw + ${rhythm(3)})`}
        className={css`
          ${{
            gridTemplateColumns: 'repeat(auto-fill, minmax(16rem, 1fr))'
          }};
        `}
      >
        <IconBox withNumber>
          <Img
            fixed={data.icon1.childImageSharp.fixed}
            alt="Clear project goals"
          />
          <h3>Clear project goals</h3>
          <p>
            We’ll discuss what your business needs most to thrive, and focus on
            it during the whole project.
          </p>
        </IconBox>
        <IconBox withNumber>
          <Img fixed={data.icon2.childImageSharp.fixed} alt="Perfect toolset" />
          <h3>Perfect toolset</h3>
          <p>
            Thanks to our expertise we’ll offer you a couple of technologies,
            that will suit your interests best.
          </p>
        </IconBox>
        <IconBox withNumber>
          <Img
            fixed={data.icon3.childImageSharp.fixed}
            alt="Transparent work environment"
          />
          <h3>Transparent work environment</h3>
          <p>
            Slack workspace, Jira board, Github repository, you’ll get access to
            all of these to always be in the know how your project’s going on.
          </p>
        </IconBox>
        <IconBox withNumber>
          <Img
            fixed={data.icon5.childImageSharp.fixed}
            alt="Afterproject relationship"
          />
          <h3>Afterproject relationship</h3>
          <p>
            Our team will be happy to stay with you even after the project ends,
            handling your feature requests and maintenance, so you can grow your
            business in peace.
          </p>
        </IconBox>
      </SectionContent>
    </Section>
    <Section
      header={
        <>
          <h2>Testimonials</h2>
        </>
      }
    >
      <SectionContent
        rowGap="1"
        columnGap={`calc(4vw + ${rhythm(3 / 2)})`}
        maxWidth="44"
        className={css`
          ${{
            gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))'
          }};
        `}
      >
        <SmallTestimonials
          elements={[
            {
              content: `Our loading times greatly improved, from five or six seconds to 400 milliseconds.`,
              author: `Alen Todorov`,
              role: `Marketing and Growth Manager, SEOmonitor`,
              photo: `/img/alen-todorov.jpg`
            },
            {
              content: `The successful switch to JAMstack has increased website and data security while also improving site performance and speed.`,
              author: `Yan Schuppli`,
              role: `Founder, Codevelop Technologies GmbH`,
              photo: `/img/yan-schuppli.jpg`
            },
            {
              content: `Bejamas’ responsiveness and ability to turn constructive feedback into implementable, on-time deliverables are hallmarks of their work. Their can-do attitude made them a valuable partner.`,
              author: `Paolo Stolfo`,
              role: `Art Director, KREAM`,
              photo: `/img/paolo-stolfo.jpg`
            }
          ]}
        />
      </SectionContent>
    </Section>
    <Section
      variant="lightGray"
      header={
        <>
          <Subheading>JAMstack Universe</Subheading>
          <h2>
            Learn more about fast <br />
            JAMstack marketing websites
          </h2>
        </>
      }
    >
      <SectionContent
        wide
        columnGap="2"
        className={css`
          ${{
            gridTemplateColumns: 'repeat(auto-fill, minmax(16rem, 1fr))'
          }};
        `}
      >
        {data.relatedPosts &&
          data.relatedPosts.edges.map(({ node }) => <PostTile post={node} />)}
      </SectionContent>
    </Section>
    <Section
      header={
        <>
          <Subheading>Looking for other services?</Subheading>
          <h2>Progressive Web Apps</h2>
          <Lead>
            Build a fast and scalable web JAMstack app <br />
            with all the native app’s features
          </Lead>
        </>
      }
      footer={<Button to="/services/progressive-web-app/">Read more</Button>}
      footerClassName={css`
        ${{ marginTop: rhythm(2) }};
      `}
    />
  </Layout>
)

export const query = graphql`
  query ServicesJamstackPageQuery {
    hero: file(
      relativePath: { regex: "/img/services/jamstack/" }
      name: { eq: "hero" }
    ) {
      childImageSharp {
        fluid(maxWidth: 640, traceSVG: { color: "#ff5d3e" }) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
          presentationWidth
        }
      }
    }
    whatIllu: file(
      relativePath: { regex: "/img/services/jamstack/" }
      name: { eq: "What_is_JAM" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500, traceSVG: { color: "#ff5d3e" }) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
          presentationWidth
        }
      }
    }
    relatedPosts: allMarkdownRemark(
      filter: { frontmatter: { category: { regex: "/case-studies/" } } }
      limit: 3
    ) {
      edges {
        node {
          frontmatter {
            title
            excerpt
            image {
              childImageSharp {
                fluid(maxWidth: 350) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  presentationWidth
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
    caseStudy: file(
      relativePath: { regex: "/img/services/jamstack/" }
      name: { eq: "JAM_case_study" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500, traceSVG: { color: "#ff5d3e" }) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
          presentationWidth
        }
      }
    }
    benefitPerformance: file(
      relativePath: { regex: "/img/services/" }
      name: { eq: "JAM_performance" }
    ) {
      childImageSharp {
        fixed(height: 160, traceSVG: { color: "#110328" }) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    benefitSeo: file(
      relativePath: { regex: "/img/services/" }
      name: { eq: "JAM_seo" }
    ) {
      childImageSharp {
        fixed(height: 160, traceSVG: { color: "#110328" }) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    benefitSafety: file(
      relativePath: { regex: "/img/services/" }
      name: { eq: "JAM_safety" }
    ) {
      childImageSharp {
        fixed(height: 160, traceSVG: { color: "#110328" }) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    benefitContentManagement: file(
      relativePath: { regex: "/img/services/" }
      name: { eq: "JAM_content" }
    ) {
      childImageSharp {
        fixed(height: 160, traceSVG: { color: "#110328" }) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    benefitUptime: file(
      relativePath: { regex: "/img/services/" }
      name: { eq: "JAM_uptime" }
    ) {
      childImageSharp {
        fixed(height: 160, traceSVG: { color: "#110328" }) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    icon1: file(
      relativePath: { regex: "/img/services/" }
      name: { eq: "service_icon_01" }
    ) {
      childImageSharp {
        fixed(height: 250, traceSVG: { color: "#110328" }) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    icon2: file(
      relativePath: { regex: "/img/services/" }
      name: { eq: "service_icon_02" }
    ) {
      childImageSharp {
        fixed(height: 250, traceSVG: { color: "#110328" }) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    icon3: file(
      relativePath: { regex: "/img/services/" }
      name: { eq: "service_icon_03" }
    ) {
      childImageSharp {
        fixed(height: 250, traceSVG: { color: "#110328" }) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    icon4: file(
      relativePath: { regex: "/img/services/" }
      name: { eq: "service_icon_04" }
    ) {
      childImageSharp {
        fixed(height: 250, traceSVG: { color: "#110328" }) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
    icon5: file(
      relativePath: { regex: "/img/services/" }
      name: { eq: "service_icon_05" }
    ) {
      childImageSharp {
        fixed(height: 250, traceSVG: { color: "#110328" }) {
          ...GatsbyImageSharpFixed_withWebp_tracedSVG
        }
      }
    }
  }
`
